.new-appointment {
  &__summary {
    &__title {
      font-weight: bold;
      text-align: center;
      font-size: 30px;
    }

    &__sub-title {
      font-weight: bold;
      text-align: center;
      font-size: @font-size-lg;
      margin-bottom: @margin-1;
    }
  }

  @media screen and (max-width: 767px) {
    &__summary {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__title {
        font-size: 20px;
      }
    }
  }
}
