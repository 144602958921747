.appointment-specific {
  &__empty {
    margin-top: 32px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  &__times {
    display: grid;
    gap: @padding-2;
    grid-template-columns: repeat(4, 1fr);
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;

    &--selected {
      border: @selected-border;
    }

    .anticon {
      display: inline-flex;
      margin-right: 16px;
      font-size: 16px;
    }

    span {
      font-size: 20px;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    &__times {
      grid-template-columns: repeat(2, 1fr);
    }

    &__time {
      .anticon {
        font-size: 14px;
      }

      span {
        font-size: 16px;
      }
    }
  }
}
