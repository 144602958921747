.header-message {
  display: block;
  text-align: center;
  margin: @margin-5 0 @margin-4;

  &__title {
    font-size: 30px;
    font-weight: bold;
  }

  &__description {
    margin-top: @margin-1;
  }

  @media screen and (max-width: 767px) {
    &__title {
      font-size: 20px;
    }

    &__description {
      font-size: @font-size-sm;
    }
  }
}
