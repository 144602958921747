.forgotten-password {
  &--message {
    display: block;
    text-align: center;
    margin-bottom: @margin-3;

    .anticon {
      font-size: 120px;
    }

    h2 {
      margin-bottom: @margin-1;
    }
  }
}
