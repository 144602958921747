.list {
  .crm-filter-search {
    display: block;
    margin-bottom: @margin-3;

    .ant-form-item-label {
      display: none;
    }
  }

  .ant-list-item {
    padding: 0;
    margin-bottom: @margin-1;
    border-bottom: 0;
  }

  &-item {
    .border-base;
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    min-height: 48px;
    padding: @padding-2;

    &:hover {
      .border-radius-base;
      background-color: #f5f5f5;
      cursor: pointer;
    }

    &-avatar {
      .secondary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background-color: #f5f5f5;
      font-size: 18px;
    }

    &-content {
      flex: 1;
      margin-left: @margin-2;
    }

    &-title {
      display: flex;
      font-size: @font-size-lg;
      font-weight: 500;

      span:not(:last-child):after {
        .secondary-color;
        content: '•';
        margin: 0 @margin-1;
      }
    }

    &-info {
      .secondary-color;
      display: flex;
      flex-wrap: wrap;

      span:not(:last-child):after {
        .secondary-color;
        content: '•';
        margin: 0 @margin-1;
      }
    }
  }

  .ant-pagination {
    margin-top: @margin-2;
    text-align: center;
  }

  @media (max-width: 767px) {
    &-item {
      &-title {
        font-size: @font-size-base;
      }

      &-info {
        font-size: @font-size-sm;
      }
    }
  }
}
