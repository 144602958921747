.appointment-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .ant-result {
    padding: 0;

    &-title {
      font-size: 30px;
      font-weight: 500;
      line-height: 37.5px;
    }
  }

  @media (max-width: 767px) {
    .ant-result-title {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
