.crm-auth-wrapper {
  .crm-auth-wrapper--content {
    max-width: 480px;
  }

  .crm-auth-wrapper--image {
    padding: 40px;

    .login-image {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
      background-color: #ffffff;

      img {
        max-width: 80%;
        max-height: 80%;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;

    .crm-auth-wrapper--content-wrapper {
      min-height: 0;
      margin-top: @margin-4;
      order: 2;
    }

    .crm-auth-wrapper--image {
      order: 1;
      min-height: 260px;
      width: 100%;
      padding: 0;
    }
  }
}

.crm-auth-success {
  .crm-auth-wrapper--logo {
    max-height: 60px;
  }
}

.crm-login {
  .crm-auth-wrapper {
    &--content {
      display: flex;
      flex-direction: column;

      &-body {
        order: 1;
      }

      &-header {
        order: 2;
      }
    }

    &--logo {
      margin-top: 64px;
      margin-bottom: 0;

      img {
        width: 40px;
      }
    }
  }

  .login-logo {
    position: relative;
    margin-bottom: @margin-1;

    img {
      height: 40px;
      max-width: 100%;
    }
  }

  .login-error {
    margin: 24px 0;
  }

  .login-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .login-text {
    font-size: 16px;
  }

  .login-forgotten-password {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 16px;

    &:hover {
      text-decoration: underline;
    }

    i {
      margin-right: 8px;
    }
  }

  .login-no-account {
    display: flex;
    justify-content: center;
    margin: 24px 0;
  }

  @media (max-width: 767px) {
    .login-title {
      font-size: 20px;
    }

    .login-text {
      font-size: 14px;
    }
  }
}

.crm-forgotten-password,
.crm-reset-password {
  .crm-auth-wrapper--logo {
    margin-bottom: 32px;
    background-image: url(/assets/icons/lock.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 128px;

    img {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .crm-auth-wrapper--logo {
      height: 72px;
      margin-bottom: 24px;
    }
  }
}

.crm-forgotten-password {
  .crm-forgotten-password--info {
    margin-bottom: 32px;
  }

  @media (max-width: 767px) {
    .crm-forgotten-password--title {
      font-size: 20px;
    }

    .crm-forgotten-password--text {
      font-size: 14px;
    }

    .crm-auth-wrapper--logo {
      height: 72px;
      margin-bottom: 24px;
    }
  }
}

.crm-reset-password {
  .crm-reset-password--info {
    margin-bottom: 32px;
  }

  .crm-reset-password--title {
    margin-bottom: 16px;
  }

  .crm-reset-password--text {
    font-size: 16px;
  }

  @media (max-width: 767px) {
    .crm-reset-password--title {
      font-size: 20px;
    }

    .crm-reset-password--text {
      font-size: 14px;
    }
  }
}

.crm-register-info {
  text-align: center;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 24px;
    width: 72px;
    height: 72px;
    border-radius: 100%;

    i {
      font-size: 24px;
    }

    &.success {
      background-color: #f6ffed;

      i {
        color: #52c41a;
      }
    }

    &.warning {
      background-color: #fff7e6;

      i {
        color: #fa8c16;
      }
    }
  }

  &-title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  &-text {
    font-size: 16px;
  }

  &-actions {
    margin-top: 32px;

    .ant-btn + .ant-btn {
      margin-top: 8px;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &-icon {
      margin-bottom: 32px;
    }

    &-title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &-text {
      font-size: 14px;
    }
  }
}
