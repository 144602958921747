.crm-action-button {
  .button-icon-right {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      order: 2
    }

    span {
      margin-left: 0;
      margin-right: 8px;
      line-height: 16px;
    }
  }
}
