.ant-steps.ant-steps-horizontal {
  .ant-steps-item-container {
    display: flex;
    align-items: center;
  }

  .ant-steps-item-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .ant-steps-item-process {
    > .ant-steps-item-container {
      > .ant-steps-item-content {
        > .ant-steps-item-title {
          color: @primary-color;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: space-between;

    .ant-steps-item-container {
      flex-direction: column;
    }

    .ant-steps-item {
      padding-left: 0;
      display: flex;
      justify-content: flex-start;
    }

    .ant-steps-item-icon {
      margin-right: 0;
      width: 40px;
      height: 40px;
    }

    .ant-steps-item-title {
      padding-right: 0;
      font-size: @font-size-base;

      &::after {
        top: -20px;
        margin-left: 4px;
      }
    }
  }
}
