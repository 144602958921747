.item-picker {
  .ant-collapse {
    > .ant-collapse-item {
      .border-base;
      margin-bottom: @margin-1;

      &-active {
        border: @selected-border;
      }

      > .ant-collapse-header {
        display: flex;
        align-items: center;

        > div:first-child {
          display: none;
        }
      }

      > .ant-collapse-content {
        > .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }

  &__title {
    flex: 1;
    min-width: 0;
    margin: 0 @margin-2;
    font-size: @font-size-lg;
    font-weight: bold;
  }

  &__content {
    padding: 0 @padding-2 @padding-2;
  }

  @media (max-width: 767px) {
    &__title {
      font-size: @font-size-base;
    }
  }
}
