@typography-title-margin-bottom: 0;

@text-color: #595959;
@text-color-secondary: #8c8c8c;

.typography-paragraph() {
  margin-bottom: 0;
}

.secondary-color {
  color: @text-color-secondary;
}

.text-ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
