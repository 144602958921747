.crm-layout-header {
  display: flex;
  padding: 0 24px;
  background-color: #ffffff;
  line-height: 1.5;
  border-bottom: 1px solid #d9d9d9;
}

.crm-layout-fragment-logo {
  display: flex;
  flex: 1;
  height: 100%;
  padding: 16px 0;

  crm-layout-logo {
    height: 100%;
  }

  .crm-layout-logo {
    height: 100%;
    padding: 0;
    margin-left: 0;

    crm-logo, .crm-logo {
      height: 100%;

      img {
        height: 100%;
      }
    }
  }
}

.crm-layout-fragment-locale {
  display: flex;
  align-items: center;
  padding-right: 32px;
  border-right: 1px solid #d9d9d9;
  margin: 16px 0;

  @media (max-width: 767px) {
    display: none;
  }
}

.crm-layout-fragment-sign-out {
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding-left: 32px;
  color: @primary-color;
  text-transform: uppercase;

  @media (max-width: 767px) {
    display: none;
  }
}

.crm-layout-fragment-nav-mobile {
  display: none;

  @media (max-width: 767px) {
    display: flex;
    align-items: center;
    margin: 16px 0;
  }
}

.nav-mobile-drawer {
  .ant-drawer-header {
    padding: 16px;

    &-title {
      justify-content: flex-end;
    }
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
  }

  .ant-drawer-close {
    margin: 0;
  }

  .crm-layout-logo {
    height: 96px;

    img {
      height: 40px;
    }
  }

  .login-image {
    max-height: 260px;
    width: 100%;

    img {
      width: 100%;
    }
  }
}
