/* You can add global styles to this file, and also import other style files */

// https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '../node_modules/common-module/common-module.less';

@primary-color: #008353;

@background-color-base: #f7f7f7;
@layout-body-background: @background-color-base;

@form-vertical-label-padding: 0 0 2px;

body {
  background-color: @background-color-base;
}

@import "./assets/styles";
