.date-picker-block {
  width: 100%;

  .ant-picker-dropdown {
    flex: 1;
  }

  .ant-picker-panel-container {
    box-shadow: none;
    border: @border-base;
  }

  .ant-picker-panel {
    border: 0;
    width: 100%;
  }

  .ant-picker-date-panel,
  .ant-picker-month-panel,
  .ant-picker-year-panel {
    width: 100%;
  }

  .ant-picker-content {
    width: 100%;

    th, td {
      padding: 0;
      height: 60px;
    }

    td:before {
      height: 60px;
    }
  }

  .ant-picker-cell-today {
    .ant-picker-cell-inner:before {
      content: none;
    }
  }

  .ant-picker-cell-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: #ffffff;
      border: @selected-border;
      color: @text-color;
      transition: none;
    }
  }

  .ant-picker-header {
    border-bottom-color: @border-color-base;
  }

  .ant-picker-footer {
    border-top-color: @border-color-base;
    border-bottom: 0;
  }


  @media (max-width: 767px) {
    .ant-picker-content {
      th, td {
        padding: 0;
        height: 40px;
      }

      td:before {
        height: 40px;
      }
    }
  }
}
