.ant-modal-footer {
  padding: 16px 24px;

  .crm-action-buttons {
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {
  .ant-modal {
    width: 100dvw !important;
    height: 100dvh;
    max-width: 100dvw;
    margin: 0;
    padding: 0;
    top: 0;
  }

  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-body {
    flex: 1;
    min-height: 0;
    overflow: auto;
  }

  .ant-modal-footer {
    flex-direction: column;

    .ant-btn {
      width: 100%;

      & + .ant-btn:not(.ant-dropdown-trigger) {
        margin-left: 0;
        margin-top: 8px;
      }
    }

    .crm-action-buttons {
      display: flex;
      flex-direction: column;
      justify-content: unset;

      crm-action-button {
        margin: 0 0 @margin-1;
      }
    }
  }
}
