.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fs-20 {
  font-size: 20px;
}
