.ant-modal-body {
  .ant-result {
    padding: 0;

    &-title {
      line-height: 1.5;
      margin-bottom: @margin-2;
    }

    &-subtitle {
      line-height: 1.5;
    }

    &-extra {
      display: flex;

      .ant-btn {
        flex: 1;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .ant-result {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-icon {
        margin-top: auto;
      }

      &-extra {
        margin-top: auto;
      }
    }
  }
}
