.appointment-detail {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: @margin-5;

    .ant-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;

      .anticon {
        font-size: 30px;
      }
    }
  }

  &__title {
    margin: @margin-1 0;
    font-size: 30px;
    font-weight: bold;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
  }

  &__cancel-appointment {
    margin-right: auto;
  }

  &__action-row {
    &__header {
      display: flex;

      &--editing {
        margin-bottom: @margin-1;
      }
    }

    &__title {
      flex: 1;
    }

    &__actions {
      .ant-btn {
        margin-left: @margin-2;
        align-self: flex-start;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &__title {
      font-size: 22px;
    }
  }
}
