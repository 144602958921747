@border-color-base: #d9d9d9;
@border-radius-base: 4px;

@border-base: @border-width-base @border-style-base @border-color-base;
@selected-border: 2px @border-style-base @primary-color;

.border-radius-base {
  border-radius: @border-radius-base;
}

.border-base {
  .border-radius-base;
  border: @border-base;
}
